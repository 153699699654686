<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <!-- <StatisticalSearch :searchType="searchType"
                         @getData="getData"
                         :isGetTable="isGetTable"></StatisticalSearch> -->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.region')" prop="areaId">
                <a-cascader ref="cascader"></a-cascader>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Vehicle_yard_business_type')" prop="transitionType">
                <el-select v-model.trim="formInline.transitionType" filterable size="15">
                  <el-option label="全部" value></el-option>
                  <el-option
                    :label="item.desc"
                    :value="item.code"
                    :key="item.code"
                    v-for="item in businessTypes"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Belonging_operator')">
                <a-operation-select ref="operationSelect"></a-operation-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
                <a-park-type-select ref="parkTypeSelect"></a-park-type-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Payment_Time')">
                <a-date-picker ref="datePicker" :selectkeys="selectkeys"></a-date-picker>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.date')" prop="dateType" style="width:200px;">
            <el-select
                v-model.trim="formInline.dateType"
                filterable
                size="15"
                style="width:100px;"
            >
            <el-option label="日" value="day">日</el-option>
            <el-option label="月" value="month">月</el-option>
          </el-select>
        </el-form-item>
          <timeRangePick
            @timeChange="timeChange"
            ref="timeRangePicker"
            :defalutDate = "defaultTime"
            :type = "formInline.dateType == 'day' ? 'daterange' : 'monthrange'"
            :valueFormat = "formInline.dateType == 'day' ? 'yyyy-MM-dd' : 'yyyy-MM'"
          /> -->
              <!-- <search-data-picker
            ref="searchData"
            style="display:inline-block"
            @getData="getData"
            :searchType="searchType"
          ></search-data-picker> -->
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="clearData()" :loading="loading"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <!-- 其他按钮 -->
            </div>
            <div class="col_right mbd4">
              <!-- 导出或下载按钮 -->
              <el-button
                type="info"
                icon="el-icon-upload2"
                :loading="loading"
                v-if="$route.meta.authority.button.export"
                @click="exportExcelReport()"
                >{{ $t('button.export') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <LineChart class="graphShadow marginTB20 paddingT10 paddingL20"></LineChart>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingT10 paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import timeRangePick from "@/components/timePicker"
import {
  // getDt,
  // setIndex
  formatNum,
  exportExcelNew,
} from "../../../common/js/public.js";
// import StatisticalSearch from '../../../components/statisticalSearch/statisticalSearch';
import LineChart from "./lineChart2";
// import searchDataPicker from "../../../components/dateTimePicker/dateTimePicker";
import { getZeroOrLastDateTime } from "@/common/js/utils";
export default {
  name: "regionArrearsRecoverAnalysis",
  data() {
    const startTime = getZeroOrLastDateTime("yyyy-MM-dd", -31);
    const endTime = getZeroOrLastDateTime("yyyy-MM-dd", -1);
    return {
      selectkeys: ["date", "month", "week", "year"],
      defaultTime: [startTime, endTime],
      isGetTable: false,
      total: 0,
      pageNum: 1,
      pageSize: 15,
      searchType: "arrears",
      loading: false,
      type1: "c1",
      type2: "c2",
      tableCols: [
        {
          prop: "areaName",
          label: "地区",
          width: "",
        },
        {
          prop: "dataDate",
          label: "期间范围",
          width: "",
          formatter: (row, column) => {
            if (row.dataDate) {
              return `${row.dataDate.split("~")[0]} - ${row.dataDate.split("~")[1]}`;
            } else {
              return "";
            }
          },
        },
        {
          prop: "pdarecovermoney",
          label: "欠费追缴金额(元)",
          width: "",
          formatter: (row, column) => {
            if (row.pdarecovermoney) {
              return formatNum(row.pdarecovermoney / 100).indexOf(".") > 1
                ? formatNum(row.pdarecovermoney / 100)
                : formatNum(row.pdarecovermoney / 100);
            } else {
              return "0.00";
            }
          },
        },
      ],
      tableData: [],
      formInline: {
        transitionType: "",
        dateType: "day",
        startTime: "",
        endTime: "",
      },
      dateType: "day",
      businessTypes: [],
    };
  },
  methods: {
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    // 清空
    clearData() {
      this.$refs.cascader.clear();
      this.formInline.transitionType = "";
      this.formInline.dateType = "day";
      // this.$refs["searchData"].getTimeNow();
      // this.$refs.timeRangePicker.resetTime();
      this.$refs.operationSelect.clear();
      this.$refs.datePicker.clear();
      this.$refs.parkTypeSelect.clear();
    },
    // 导出
    exportExcelReport() {
      // let flag = this.showLog();
      // if (!flag) return
      // let dateNum = 1
      // if (this.formInline.dateType == 'day') {
      //   dateNum = 1
      // } else {
      //   dateNum = 2
      // }
      let opt = {
        areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
        businessType: this.formInline.transitionType,
        dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
        operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
        // parkType: this.formInline.transitionType,
        dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
        startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
      };
      exportExcelNew("/acb/2.0/areaStatistic/exportArrearagePay", opt, "post");
    },
    // 格式化时间
    datejoin() {
      let dateStart = this.formInline.startTime;
      let dateEnd = this.formInline.endTime;
      return dateStart + "&" + dateEnd;
    },
    // 获取业务类型
    getBusinessTypes() {
      this.$axios.get("/acb/2.0/park/businessTypes").then((res) => {
        this.businessTypes = res.value;
      });
    },
    getData(data) {
      let json = {};
      json = Object.assign({}, this.formInline, data);
      this.formInline = json;
    },
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        let time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        let diff = "";
        if (this.formInline.dateType === "day") {
          if (time >= 31) {
            this.$alert("查询时间不能大于31天");
            return false;
          } else {
            return true;
          }
        } else {
          if (time > 365) {
            this.$alert("查询时间不能大于12个月");
            return false;
          } else {
            return true;
          }
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    // 获取各区收入统计
    searchData() {
      // let flag = this.showLog();
      // if (!flag) return
      // let dateNum = 1
      // if (this.formInline.dateType == 'day') {
      //   dateNum = 1
      // } else {
      //   dateNum = 2
      // }
      this.loading = true;
      this.getAmountDetail();
      this.$axios
        .get("/acb/2.0/areaStatistic/amount/", {
          data: {
            total: 0,
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            businessType: this.formInline.transitionType,
            dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            // parkType: this.formInline.transitionType,
            dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
          },
        })
        .then((res) => {
          // for (var i = 0; i < res.value.length; i++) {
          //   res.value[i].dataDate = this.dateType()
          // }
          // this.$emit('getData', res.value);
          this.tableData = res.value.list;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 获取分析数据
    getAmountDetail(data) {
      this.$axios
        .get("/acb/2.0/areaStatistic/amountDetail/", {
          data: {
            chart: 1,
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            businessType: this.formInline.transitionType,
            dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            // parkType: this.formInline.transitionType,
            dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
          },
        })
        .then((res) => {
          this.$EventBus.$emit("getArrearageAmount", res.value);
        });
    },
  },
  components: {
    // StatisticalSearch,
    LineChart,
    // searchDataPicker,
    // timeRangePick
  },
  beforeDestroy() {},
  created() {
    this.getBusinessTypes();
  },
  computed: {},
  mounted() {
    this.searchData();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus"></style>
